<template>
  <b-modal
    v-model="isShowModal"
    centered
    hide-header-close
    title="History Chat"
  >
    <div>
      <div
        :class="`${
          chatList.length > 0
            ? 'msg_history conversation-user scroll-channels px-3 py-3'
            : ''
        }`"
        id="list-chat"
      >
        <div v-for="(message, index) in chatList" :key="index">
          <!-- sender -->
          <div v-if="message.isASender" class="outgoing_msg">
            <div class="incoming_msg_img sender">
              <img class="border-circle" :src="message.avatar" alt="sunil" />
            </div>
            <div class="sent_msg">
              <p>{{ message.content }}</p>
              <span class="time_date">{{ message.messagesTimeSend }}</span>
            </div>
          </div>
          <!-- receiver -->
          <div v-else class="incoming_msg">
            <div class="incoming_msg_img">
              <img class="border-circle" :src="message.avatar" alt="sunil" />
            </div>
            <div class="received_msg">
              <div class="received_withd_msg">
                <p>{{ message.content }}</p>
                <span class="time_date">{{ message.messagesTimeSend }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-3 py-3" v-if="chatList.length === 0">
        No History Message
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          size="sm"
          class="float-right text-white px-4 py-2"
          variant="success"
          @click="closeModal"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import ChatService from '@/services/chat.service.js';
import moment from 'moment';
import { Toastmixin } from '@/mixins/toastMinxin';
export default {
  name: 'ConversationModal',
  mixins: [Toastmixin],
  data() {
    return {
      chatList: [],
      isShowModal: false,
    };
  },
  methods: {
    closeModal() {
      this.isShowModal = false;
      this.chatList = [];
    },
    getMessage(ids) {
      ChatService.conversationReportUser(ids)
        .then((res) => {
          this.isShowModal = true;
          this.chatList = [];
          this.totalMessage = res.count;
          res.results &&
            res.results.length &&
            res.results.map((el) => {
              this.pushMessageIntoChanel(el, ids);
            });
          this.sortMessages();
          if (res.count > 0) {
            setTimeout(() => {
              this.scrollToBottomListMessage();
            }, 100);
          }
        })
        .catch(() => {
          this.isShowModal = true;
        });
    },
    pushMessageIntoChanel(messageObj, ids) {
      this.chatList.push({
        content: messageObj.content,
        messagesTimeSend: this.findLasMessagetime(messageObj.created),
        id: messageObj.id,
        status: messageObj.status,
        isASender: ids.user_from === messageObj.sender.user_id ? true : false,
        avatar: messageObj.sender.avatar.url,
        name: messageObj.sender.name,
        timeToSort: messageObj.created,
      });
    },
    findLasMessagetime(time) {
      moment.tz.setDefault('Etc/UTC');
      return moment(time).fromNow();
    },
    sortMessages() {
      this.chatList.sort((a, b) => {
        let dateA = new Date(a.timeToSort);
        let dateB = new Date(b.timeToSort);
        return dateA - dateB;
      });
    },
    scrollToBottomListMessage() {
      let objDiv = document.getElementById('list-chat');
      setTimeout(() => {
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 0);
    },
  },
};
</script>
